// 缺陷分析详情页面接口
import axios from "./ajax.js"; // 导入ajax

// 初始化数据
export const getphotoInit = params => {
  return axios({
    url: `/eprdms/photo/init`,
    method: "get",
    params
  });
};


/**
 * @Author KR0288
 * @Date 2022/05/18 09:52:46
 * @Description 根据任务查询图片
 */
export const getSelectPhotos = data => {
  return axios({
    url: `/eprdms/photo/get`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 09:53:28
 * @Description 查询单张图片
 */
export const getselectSinglePhoto = params => {
  return axios({
    url: `/eprdms/photo/get/one`,
    method: "get",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 09:55:46
 * @Description 更新图片的状态
 */
export const postDefectDetailsByName = data => {
  return axios({
    url: `/eprdms/photo/update`,
    method: "post",
    data
  });
};

// 自定义缺陷等级
export const getDefectLevels = params => {
  return axios({
    url: `/eprdms/dict/defect/level`,
    method: "get",
    params
  });
};

// 自定义缺陷等级
export const getDefectSources = params => {
  return axios({
    url: `/eprdms/dict/defect/source`,
    method: "get",
    params
  });
};

// 自定义识别类型
export const getRecognizeTypes = params => {
  return axios({
    url: `/eprdms/dict/recognize/type`,
    method: "get",
    params
  });
};

// 自定义缺陷类型
export const getDefectStatus = params => {
  return axios({
    url: `/eprdms/dict/defect/status`,
    method: "get",
    params
  });
};


//   获取所有缺陷
export const getAllDefect = params => {
  return axios({
    url: `/eprdms/errand/defect/get/page`,
    method: "get",
    params
  });
};

//   获取所有缺陷
export const filterDefect = data => {
  return axios({
    url: `/eprdms/errand/defect/get/all`,
    method: "post",
    data
  });
};

// 保存自定义缺陷
export const saveDefect = data => {
  return axios({
    url: `eprdms/errand/defect/add`,
    method: "post",
    data
  });
};

// 修改自定义缺陷
// /eprdms/defect/update/detail
export const updateDefect = data => {
  return axios({
    url: `/eprdms/errand/defect/update`,
    method: "post",
    data
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 09:59:39
 * @Description 删除用户自定义缺陷
 */
export const deleteUserDefect = params => {
  return axios({
    url: `/eprdms/errand/defect/delete`,
    method: "get",
    params
  });
};

/*
 * @Date 2022/04/11 11:17:11
 * 查询图片缺陷区域
 */
export const getPhotoDefects = params => {
  return axios({
    url: `/eprdms/photo/defect/region/get`,
    method: "get",
    params
  });
};

/*
 * @Date 2022/04/11 11:17:11
 * 更新图片缺陷区域
 */
export const updatePhotoDefect = data => {
  return axios({
    url: `/eprdms/photo/defect/region/update`,
    method: "post",
    data
  });
};

/*
 * @Date 2022/04/11 11:17:11
 * 更新图片缺陷区域
 */
export const addPhotoDefect = data => {
  return axios({
    url: `/eprdms/photo/defect/region/add`,
    method: "post",
    data
  });
};

/*
 * @Date 2022/04/11 11:17:11
 * 删除图片缺陷区域
 */
export const deletePhotoDefect = params => {
  return axios({
    url: `/eprdms/photo/defect/region/delete`,
    method: "get",
    params
  });
};

/*
 * @Date 2022/04/15 08:48:15
 * 更新图片的状态
 */
export const updatePhotoStatus = data => {
  return axios({
    url: `/eprdms/photo/update`,
    method: "post",
    data
  });
};
